// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
@import url('https://fonts.googleapis.com/css?family=Poppins:400');

:root,
:root[mode='md'],
:root[mode='ios'] {
  --ion-font-family: 'Poppins', sans-serif;
  font-family: var(--ion-font-family) !important;
}

:root {
  --ion-color-primary: #bc2026;
  --ion-color-primary-rgb: 188, 32, 38;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #a51c21;
  --ion-color-primary-tint: #c3363c;

  --ion-color-secondary: #ff8400;
  --ion-color-secondary-rgb: 255, 132, 0;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e07400;
  --ion-color-secondary-tint: #ff9e21;

  --ion-color-tertiary: #fe7573;
  --ion-color-tertiary-rgb: 255, 183, 0;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e06765;
  --ion-color-tertiary-tint: #fe8381;

  --ion-color-success: #73c501;
  --ion-color-success-rgb: 115, 197, 1;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #65ad01;
  --ion-color-success-tint: #81cb1a;

  --ion-color-warning: #e0b910;
  --ion-color-warning-rgb: 224, 185, 16;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c5a30e;
  --ion-color-warning-tint: #e3c028;

  --ion-color-danger: #ef291d;
  --ion-color-danger-rgb: 239, 41, 29;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d2241a;
  --ion-color-danger-tint: #f13e34;

  --ion-color-dark: #890214;
  --ion-color-dark-rgb: 137, 2, 20;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #790212;
  --ion-color-dark-tint: #951b2c;

  --ion-color-medium: #e5a5a8;
  --ion-color-medium-rgb: 229, 165, 168;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #ca9194;
  --ion-color-medium-tint: #e8aeb1;

  --ion-color-light: #f0f0e6;
  --ion-color-light-rgb: 240, 240, 230;
  --ion-color-light-contrast: #890214;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d3d3ca;
  --ion-color-light-tint: #f2f2e9;
}

@font-face {
  font-family: 'Poppins-Medium' !important;
  src: url('src/assets/fonts/Poppins-Medium.ttf') format('truetype');
}
